import React from "react";
import { useSelector } from "react-redux";
import { BackButton } from "../buttons/back/BackButton";
import { NavBar } from "../navbar/NavBar";
import { Lists } from "../ui/lists/Lists";
import { Subtitle } from "../ui/subtitle/Subtitle";
export const CompetenciesAdminScreen = () => {
    const { activePosition}  = useSelector(state => state.position);
    const {name, code} = activePosition;
    const {competencies} = useSelector(state => state.competencie);
  return (
    <div>
      <NavBar />
      <div className="container">
        <Subtitle value="Editar Competencias"/>
        <Lists rows={competencies} idList='competencies' edit={activePosition} info={true} infoPath='path' />
      </div>
      <BackButton path="/positions" />
    </div>
  );
};
