import { fetchConToken } from "../../helpers/fetch";
import { selfAssesmentTypes } from "./selfAssesmentTypes";

export const noteStartLoading = (collaborator) => {
    let countc, countr, countf = 0;
    return async(dispatch) => {
        try {
                const resp = await fetchConToken(`notes/get/${collaborator.id}`);
                const body = await resp.json();
                const notes = body.notes;
                if(notes === "null"){
                    dispatch(notesCommonsLoaded(null));
                }else{
                    notes.map((note) => {
                        if(note.note === '3'){
                            dispatch(notesRanksLoaded(note));
                            countr++;
                        }
                        if(note.note === '4'){
                            dispatch(notesCommonsLoaded(note));
                            countc++;
                        }
                        if(note.note === '5'){
                            dispatch(notesFunctionalsLoaded(note));
                            countf++;
                        }
                    });
//                    dispatch(countsNotes({countc, countr, countf}));
                } 
            } catch (error) {
            console.error(error);
        }
    }
}
const countsNotes = (counts) => ({
    type: selfAssesmentTypes.countsNotes,
    payload: counts,
});
export const updateNotesCommons = (id, notes) => {
    if(notes.notea === '')
        notes.notea = 0
    const newNotes = {
        id: id,
        note: notes.notea,
        observation: notes.observation
    };
    return (dispatch) => {
 //   
      dispatch(addNotesCommons(newNotes));
    }
}

const deleteNotesCommons = (notes) => ({
    type: selfAssesmentTypes.deleteNotesCommons,
    payload: notes,
})

const addNotesCommons = (notes) => ({
    type: selfAssesmentTypes.addNotesCommons,
    payload: notes,
})
const notesCommonsLoaded = (notes) => ({
    type: selfAssesmentTypes.notesCommonsLoaded,
    payload: notes,
})

const notesRanksLoaded = (notes) => ({
    type: selfAssesmentTypes.notesRanksLoaded,
    payload: notes,
})

const notesFunctionalsLoaded = (notes) => ({
    type: selfAssesmentTypes.notesFunctionalsLoaded,
    payload: notes,
})

export const openMSelfCommons = () => ({type: selfAssesmentTypes.openMCommons});
export const closeMSelfCommons = () => ({type: selfAssesmentTypes.closeMCommons});
export const openMSelfRanks = () => ({type: selfAssesmentTypes.openMRanks});
export const closeMSelfRanks = () => ({type: selfAssesmentTypes.closeMRanks});
export const openMSelfFunctionals = () => ({type: selfAssesmentTypes.openMFunctionals});
export const closeMSelfFunctionals = () => ({type: selfAssesmentTypes.closeMFunctionals});
export const openMSelfCommitments = () => ({type: selfAssesmentTypes.openMCommitments});
export const closeMSelfCommitments = () => ({type: selfAssesmentTypes.closeMCommitments});