export const selfAssesmentTypes = {
    notesCommonsLoaded:  '[selfAssesment] Notes Loaded Commons',
    notesRanksLoaded:  '[selfAssesment] Notes Loaded Ranks',
    notesFunctionalsLoaded:  '[selfAssesment] Notes Loaded Functionals',
    openMCommons: '[selfAssesment] Open Modal Commons',
    closeMCommons: '[selfAssesment] Close Modal Commons',
    openMRanks: '[selfAssesment] Open Modal Ranks',
    closeMRanks: '[selfAssesment] Close Modal Ranks',
    openMFunctionals: '[selfAssesment] Open Modal Functionals',
    closeMFunctionals: '[selfAssesment] Close Modal Functionals',
    openMCommitments: '[selfAssesment] Open Modal Commitments',
    closeMCommitments: '[selfAssesment] Close Modal Commitments',
    deleteNotesCommons: '[selfAssesment] notes Commons delete',
    addNotesCommons: '[selfAssesment] notes Commons add',
    countsNotes: '[selfAssesment] count notes by type'
}