import React from 'react'
import Modal from 'react-modal/lib/components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { MainButton } from '../buttons/main/MainButton';
import { Subtitle } from '../ui/subtitle/Subtitle';
import { closeMSelfCommons } from './selfAssesmentActions';
import { SelfAssesments } from './SelfAssesments';

const customStyles = {
    content: {
        top: 'auto',
        left: 'auto',
        right: 'auto',
        bottom: 'auto',
        marginRight: '0%',
        Transform: 'translate(-50%, -50%)',

    }
}
Modal.setAppElement('#root');
const initial = {
    name: ''
}
export const SelfCommonsModal = ({team}) => {
    const {modalCommonsOpen, notesCommons} = useSelector(state => state.self);
    const {commons} = useSelector(state=> state.common);
    const dispatch = useDispatch();
    const closeModal = () => {
        dispatch(closeMSelfCommons());
    }
    let note = [];
    let observation = [];
    notesCommons.map((row)=>{
        note[row.idnote] = row.value;
        observation[row.idnote] = row.observation;
    });
  return (
    < Modal
        isOpen = {modalCommonsOpen}
        onRequestClose = { closeModal }
        closeTimeoutMS = { 200}
        style = { customStyles }
        className = 'modal'
        overlayClassName = 'modal-fondo'
    >
        <Subtitle value="Autoevaluación Comunes" close={true} fclose={closeModal} />
        <div className='row'>
            <div className='col-10'>Recuerde el rango de calificación es de 0 a 50 </div>
              <div className='col-2'>
                  <MainButton
                      key='buttonSaveCommons'
                      nameButton='buttonSaveCommons'
                      idButton='buttonSaveCommons'
                      icon='fas floppy-disk'
                      color='FFF'
                      back='green'
                      valueButton='Guardar'
                  />
              </div>
        </div>

        { (commons === "null")
        ? <div className='alert-danger text-center m-5 p-5'><h4>No estan creadas las competencias comunes para tu cargo, por favor contacta a talento humano.</h4></div>
        : <div id="commonList" style={{ height: '72vh', overflowY: 'scroll', overflowX: 'scroll' }} className="overflow-scroll table-responsive">
                    <table className="table table-hover table-striped table-bordered border-light">
                        
                            <thead>
                                <tr className='table-info text-center'>
                                    <th>Competencia</th>
                                    <th>Conductas Asociadas</th>
                                    <th>Calificación</th>
                                    <th>Observaciones</th>
                                </tr>
                            </thead>
                    
                        <tbody>
                        {
                            commons.map((common)=>{
                                return(
                                <SelfAssesments
                                    name= {common.name}
                                    behaviours= {common.behaviours}
                                    note={(note[common.id]) ? note[common.id] : 0}
                                    observ={(observation[common.id]) ? observation[common.id] : ''}
                                    id={common.id}
                                />);
                            })
                        }
                        </tbody>
                    </table>
            </div>  
        }
    </Modal> 
  )
}
/*
<SelfAssesments 
            list={commons} 
            idList="commons"
            title1={"Competencia"}
            title2={"Conductas Asociadas"}
            title3={"Observaciones"}
            team={team}
            notes={notesCommons}
            />*/