import React from 'react'
import Modal from 'react-modal/lib/components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Subtitle } from '../ui/subtitle/Subtitle';
import { closeMSelfRanks } from './selfAssesmentActions';
import { SelfAssesments } from './SelfAssesments';

const customStyles = {
    content: {
        top: 'auto',
        left: 'auto',
        right: 'auto',
        bottom: 'auto',
        marginRight: '0%',
        Transform: 'translate(-50%, -50%)',

    }
}
Modal.setAppElement('#root');
const initial = {
    name: ''
}
export const SelfRanksModal = () => {
    const {modalRanksOpen} = useSelector(state => state.self);
    const {ranks} = useSelector(state=> state.rank);
    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(closeMSelfRanks());
    }
  return (
    < Modal
        isOpen = {modalRanksOpen}
        onRequestClose = { closeModal }
        closeTimeoutMS = { 200}
        style = { customStyles }
        className = 'modal'
        overlayClassName = 'modal-fondo'
    >
        <Subtitle value="Autoevaluación Nivel Jerarquico" close={true} fclose={closeModal} />
        <div>Recuerde el rango de calificación es de 0 a 50 </div>
        { (ranks === "null")
        ? <div className='alert-danger text-center m-5 p-5'><h4>No estan creadas las competencias comunes para tu cargo, por favor contacta a talento humano.</h4></div>
        : <SelfAssesments 
            list={ranks} 
            idList="ranks"
            title1={"Competencia"}
            title2={"Conductas Asociadas"}
            title3={"Observaciones"}
            />
        }
    </Modal> 
  )
}
