import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { BackButton } from '../buttons/back/BackButton'
import { MainButton } from '../buttons/main/MainButton'
import { commonStartLoading } from '../commons/commonActions'
import { NavBar } from '../navbar/NavBar'

import { Subtitle } from '../ui/subtitle/Subtitle'
import { noteStartLoading, openMSelfCommitments, openMSelfCommons, openMSelfFunctionals, openMSelfRanks } from './selfAssesmentActions'
import { SelfCommitmentsModal } from './SelfCommitmentsModal'
import { SelfCommonsModal } from './SelfCommonsModal'
import { SelfFunctionalsModal } from './SelfFunctionalsModal'
import { SelfRanksModal } from './SelfRanksModal'

export const SelfAssesmentScreen = () => {
  const {collaborator} = useSelector(state => state.general);
  const {competencies} = useSelector(state => state.competencie);
  const position = collaborator[0].idposition;
  const menuSelf = [];
  if(competencies !== []){
    competencies.map((c, index) => {
      if(c.id === '4')
        menuSelf.push( {name: "comunes", id: "commons", value: "Comunes", handleClick: () => {dispatch(openMSelfCommons())}});
      if(c.id === '3')
        menuSelf.push({name: "niveles", id: "ranks", value: "Nivel Jerarquico", handleClick: () => {dispatch(openMSelfRanks())}});
      if(c.id === '5')
        menuSelf.push({name: "funcionales", id: "functionals", value: "Funcionales", handleClick: () => {dispatch(openMSelfFunctionals())}});
    });
  }
  menuSelf.push({name: "competencias", id: "commitments", value: "Desempeño", handleClick: () => {dispatch(openMSelfCommitments())}});

    
    
    
  const dispatch = useDispatch();
  useEffect(() => {
      dispatch(commonStartLoading(position));
      dispatch(noteStartLoading(collaborator[0]));
  }, [dispatch]);
  return (
    <div>
      <NavBar />
      <div className="container">
        <Subtitle value="Autoevaluación" />
        <div className="row">
                {
                    menuSelf.map(menu => {
                        return(
                            <div key={menu.id} className="col-md-4 col-xs-6 p-3">  
                            <MainButton
                                key={menu.id}
                                nameButton={menu.name}
                                idButton={menu.id}
                                valueButton={menu.value}
                                handleClick={menu.handleClick}
                            />
                        </div>
                        );
                    })
                }
            </div>
      </div>
      <BackButton path="/main" />
      <SelfCommonsModal team={collaborator[0].id}/>
      <SelfRanksModal team={collaborator[0].id}/>
      <SelfFunctionalsModal team={collaborator[0].id}/>
      <SelfCommitmentsModal team={collaborator[0].id}/>
    </div>
  )
}
