import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";

import { ProfileScreen } from "../component/profile/ProfileScreen";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { startChecking } from "../actions/auth";
import { LoginScreen } from "../component/login/LoginScreen";
import { RankScreen } from "../component/rank/RankScreen";
import { DashboardScreen } from "../component/dashboard/DashboardScreen";
import { PersonalInfoScreen } from "../component/personalinfo/PersonalInfoScreen";
import { LevelScreen } from "../component/levels/LevelScreen";
import { GradeScreen } from "../component/grades/GradeScreen";
import { AreaScreen } from "../component/areas/AreaScreen";
import { PositionScreen } from "../component/positions/PositionScreen";
import { CompetenciesScreen } from "../component/competencies/CompetenciesScreen";
import { CommonScreen } from "../component/commons/CommonScreen";
import { FunctionalScreen } from "../component/functional/FunctionalScreen";
import { TeamScreen } from "../component/teams/TeamScreen";
import { EvaluatorScreen } from "../component/evaluators/EvaluatorScreen";
import { AddEvalScreen } from "../component/evaluators/AddEvalScreen";
import { Loading } from "../component/ui/loading/Loading";
import { GeneralScreen } from "../component/general/GeneralScreen";
import { CommitmentsScreen } from "../component/commitments/CommitmentsScreen";
import { GoalScreen } from "../component/goals/GoalScreen";
import { ObjectiveScreen } from "../component/objectives/ObjectiveScreen";
import { ActivitiesScreen } from "../component/activities/ActivitiesScreen";
import { TrainingScreen } from "../component/training/TrainingScreen";
import { RoutingEvaluator } from "../component/dashboard/RoutingEvaluator";
import { PreviewScreen } from "../component/preview/PreviewScreen";
import { AgreementScreen } from "../component/agreements/AgreementScreen";
import { SelfAssesmentScreen } from "../component/SelfAssessment/SelfAssesmentScreen";
import { CompetenciesAdminScreen } from "../component/competencies/CompetenciesAdminScreen";

export const AppRouter = () => {
  const dispatch = useDispatch();
  const { checking, id, type } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(startChecking());
  }, [dispatch]);
  if (checking) {
    return <Loading/>;
  }

  return (
    <Router>
      <div>
        <Switch>
          <PublicRoute
            exact
            path="/login"
            component={LoginScreen}
            isAuthenticated={!!id}
          />
          <PublicRoute
            exact
            path="/"
            component={ProfileScreen}
            isAuthenticated={!!id}
          />
          <PrivateRoute
            exact
            path='/main'
            component={DashboardScreen}
            isAuthenticated={!!id}
            typeuser={type}
            auth='all'
          />
          <PrivateRoute
            exact
            path='/personal'
            component={PersonalInfoScreen}
            isAuthenticated={!!id}
            typeuser={type}
            auth='all'
          />
          <PrivateRoute
            exact
            path='/levels'
            component={LevelScreen}
            isAuthenticated={!!id}
            typeuser={type}
            auth='administrator'
          />
          <PrivateRoute
            exact
            path='/grades'
            component={GradeScreen}
            isAuthenticated={!!id}
            typeuser={type}
            auth='administrator'
          />
          <PrivateRoute
            exact
            path='/areas'
            component={AreaScreen}
            isAuthenticated={!!id}
            typeuser={type}
            auth='administrator'
          />
          <PrivateRoute
            exact
            path='/positions'
            component={PositionScreen}
            isAuthenticated={!!id}
            typeuser={type}
            auth='administrator'
          />
          <PrivateRoute
            exact
            path='/competencies'
            component={CompetenciesScreen}
            isAuthenticated={!!id}
            typeuser={type}
            auth='administrator'
          />
          <PrivateRoute
            exact
            path='/competencies/admin'
            component={CompetenciesAdminScreen}
            isAuthenticated={!!id}
            typeuser={type}
            auth='administrator'
          />
          <PrivateRoute
            exact
            path='/team'
            component={TeamScreen}
            isAuthenticated={!!id}
            typeuser={type}
            auth='administrator'
          />
           <PrivateRoute
            exact
            path='/competencies/level'
            component={RankScreen}
            isAuthenticated={!!id}
            typeuser={type}
            auth='administrator'
          />
          <PrivateRoute
            exact
            path='/competencies/common'
            component={CommonScreen}
            isAuthenticated={!!id}
            typeuser={type}
            auth='administrator'
          />
          <PrivateRoute
            exact
            path='/competencies/function'
            component={FunctionalScreen}
            isAuthenticated={!!id}
            typeuser={type}
            auth='administrator'
          />
          <PrivateRoute
            exact
            path='/evaluators'
            component={EvaluatorScreen}
            isAuthenticated={!!id}
            typeuser={type}
            auth='administrator'
          />
          <PrivateRoute
            exact
            path='/addEval'
            component={AddEvalScreen}
            isAuthenticated={!!id}
            typeuser={type}
            auth='administrator'
          />
          <PrivateRoute
            exact
            path='/general'
            component={GeneralScreen}
            isAuthenticated={!!id}
            typeuser={type}
            auth='collaborator'
          />
          <PrivateRoute
            exact
            path='/commitments'
            component={CommitmentsScreen}
            isAuthenticated={!!id}
            typeuser={type}
            auth='collaborator'
          />
          <PrivateRoute
            exact
            path='/goals'
            component={GoalScreen}
            isAuthenticated={!!id}
            typeuser={type}
            auth='collaborator'
          />
          <PrivateRoute
            exact
            path='/objectives'
            component={ObjectiveScreen}
            isAuthenticated={!!id}
            typeuser={type}
            auth='collaborator'
          />
          <PrivateRoute
            exact
            path='/activities'
            component={ActivitiesScreen}
            isAuthenticated={!!id}
            typeuser={type}
            auth='collaborator'
          />
          <PrivateRoute
            exact
            path='/training'
            component={TrainingScreen}
            isAuthenticated={!!id}
            typeuser={type}
            auth='collaborator'
          />
          <PrivateRoute
            exact
            path='/mainEvaluator'
            component={RoutingEvaluator}
            isAuthenticated={!!id}
            typeuser={type}
            auth='evaluator'
          />
          <PrivateRoute
            exact
            path='/review'
            component={PreviewScreen}
            isAuthenticated={!!id}
            typeuser={type}
            auth='collaborator'
          />
           <PrivateRoute
            exact
            path='/agreement'
            component={AgreementScreen}
            isAuthenticated={!!id}
            typeuser={type}
            auth='evaluator'
          />
          <PrivateRoute
            exact
            path='/assesment'
            component={SelfAssesmentScreen}
            isAuthenticated={!!id}
            typeuser={type}
            auth='collaborator'
          />
        </Switch>
      </div>
    </Router>
  );
};
