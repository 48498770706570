import { object } from "prop-types"
import { selfAssesmentTypes } from "./selfAssesmentTypes"

const initSelfAssesment = {
    notesCommons: [],
    notesRanks: [],
    notesFunctionals: [],
    modalCommonsOpen: false,
    modalRanksOpen: false,
    modalFunctionalsOpen: false,
    modalCommitmentsOpen: false,
    percents: {
        self: 30,
        commons: 3,
        ranks: 3,
        functionals: 3,
        commitments: 21
    },
    notesCommonsUpdate: [],
    countc: 0,
    countr: 0,
    countf: 0
}

export const selfAssesmentReducer = (state = initSelfAssesment, action) => {
    switch (action.type) {
        case selfAssesmentTypes.openMCommons:
            return {
                ...state,
                modalCommonsOpen: true
            }
        case selfAssesmentTypes.closeMCommons:
            return {
                ...state,
                modalCommonsOpen: false
            }
        case selfAssesmentTypes.openMRanks:
            return {
                ...state,
                modalRanksOpen: true
            }
        case selfAssesmentTypes.closeMRanks:
            return {
                ...state,
                modalRanksOpen: false
            }
        case selfAssesmentTypes.openMFunctionals:
            return {
                ...state,
                modalFunctionalsOpen: true
            }
        case selfAssesmentTypes.closeMFunctionals:
            return {
                ...state,
                modalFunctionalsOpen: false
            }
        case selfAssesmentTypes.openMCommitments:
            return {
                ...state,
                modalCommitmentsOpen: true
            }
        case selfAssesmentTypes.closeMCommitments:
            return {
                ...state,
                modalCommitmentsOpen: false
            }
        case selfAssesmentTypes.notesCommonsLoaded:
            return {
                ...state,
                notesCommons: [
                    ...state.notesCommons,
                    action.payload
                ]
            }
        case selfAssesmentTypes.notesRanksLoaded:
            return {
                ...state,
                notesRanks: [
                    ...state.notesRanks,
                    action.payload
                ]
            }
        case selfAssesmentTypes.notesFunctionalsLoaded:
            return {
                ...state,
                notesFunctionals: [
                    ...state.notesFunctionals,
                    action.payload
                ]
            }
        case selfAssesmentTypes.addNotesCommons:
            if(state.notesCommonsUpdate.filter(b => (b.id === action.payload.id)).length === 0){
                return {
                    ...state,
                    notesCommonsUpdate: [
                        ...state.notesCommonsUpdate,
                        action.payload
                    ]
                }
            } else {
                return {
                    ...state,
                    notesCommonsUpdate: state.notesCommonsUpdate.map(
                        b => (b.id === action.payload.id) ? action.payload : b
                    )
                }
            }
        case selfAssesmentTypes.deleteNotesCommons:
            return {
                notesCommonsUpdate: state.notesCommonsUpdate.filter(
                    b => (b.id !== action.payload.id)
                ),
            }
        case selfAssesmentTypes.countsNotes:
            return {
                countc: action.payload.countc,
                countf: action.payload.countf,
                countr: action.payload.countr,
            }
        default:
            return state;
    }

}