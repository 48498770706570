import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateNotesCommons } from './selfAssesmentActions';


let styles = {};
export const SelfAssesments = ({ name, behaviours, note, id, observ }) => {
    styles = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        opacity: ".95"
    };
    let initCommon = {
        notea: note,
        observation: observ
    }
    const [noteValid, setNoteValid] = useState(true);
    const [formValues, setFormValues] = useState(initCommon);
    const {notea, observation } = formValues;
    const dispatch = useDispatch();
    const handleInputChange = (e) => {
       const  target  = e.target;
        if (target.value < 0 || target.value > 50) {
            setNoteValid(false);
            return;
        }
        setNoteValid(true);
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
        
    }
    useEffect(() => {
        dispatch(updateNotesCommons(id, formValues));
    }, [formValues]);
    
        return (
            <tr>
                <td>{name}</td>
                <td>{behaviours}</td>
                <td><input
                    min={0}
                    max={50}
                    type="number"
                    className={`form-control ${!noteValid && 'is-invalid'}`}
                    placeholder="0"
                    id={'note-'+id}
                    name='notea'
                    value={notea}
                    autoComplete="off"
                    onChange={handleInputChange}
                />
                </td>
                <td>
                <textarea
                    className={`form-control`}
                    placeholder="Necesitas aclarar algo?"
                    name="observation"
                    autoComplete="off"
                    value={observation}
                    onChange={handleInputChange}
                />    
                </td>
            </tr>
        )
    }
